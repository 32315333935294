.Toolbar {
  height: 5rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  padding: 0rem;
  box-sizing: border-box;
  z-index: 1
}

.NavigationItems {
  display: flex;
  flex-flow: row;
  align-items:center;
}

.NavigationItem {
  display: flex;
  padding-right: 2rem;
}

.Tuirer {
  height: 2em;
}

.Tuirer img {
  height: 100%;

  -webkit-transition: all .5s
}

.Tuirer img:hover {
  height: 120%;
  -webkit-filter: brightness(3);
 filter: brightness(3);
}


.NavigationItem a {
  text-decoration: none;
  color: #b6c6e2;
  font-size: 1.4em;
}

.NavigationItem a:hover {
  color: #491bcc;
}
