html {
  font-size: 62.5%;  /*if dflt 16px 1rem == 10px*/
  margin: 0;
  color: #e7edf2;
  font-family: 'Special Elite', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #132037;
  font-size: 1.6em;
}
