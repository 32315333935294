.Container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Particles {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1
}
.Particles > div {
  height: 100%;
}

.Paragraph {
  padding-left: 4rem;
}

.Paragraph h1 {
  font-size: 7vw;
  color: #c5c5c5;
}
